@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

@keyframes reveal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.wrapper {
  position: relative;
  img,
  video {
    @include AbsoluteImage;
  }

  // Slight gradient to increase contrast for text over image
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );
  }

  .content {
    color: $white;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;

    .title {
      cursor: pointer;
      @include SerifHero;
      @include breakpoint('mobile') {
        font-size: 48px;
      }
    }

    .textItem {
      overflow-y: clip;
      overflow-x: visible;
      padding-top: 1px;

      span {
        cursor: pointer;
        will-change: transform;
        display: block;
        transform: translateY(100%);
        transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    .cta {
      @include CtaWithBorder;
    }

    &.visible {
      .textItem {
        span {
          transform: translateY(0);
        }
      }
    }
  }

  &.medium,
  &.small {
    .content {
      .title {
        font-size: 52px;
        line-height: 1;
        @include breakpoint('mobile') {
          font-size: 32px;
        }
      }
      .cta {
        position: relative;
        top: -10px;
        @include breakpoint('mobile') {
          top: -5px;
        }
      }
    }
  }

  &.small {
    .content {
      .title {
        font-size: 36px;
        @include breakpoint('mobile') {
          font-size: 32px;
        }
      }
    }
  }
}

.link {
  display: block;
  width: 100%;
  text-decoration: none;
}

.dark {
  .content {
    color: $black;
  }
  .cta {
    &:before,
    &:after {
      background: $black !important;
    }
  }
}
