@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

@keyframes reveal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.wrapper,
.midWrapper {
  position: relative;
  color: $white;
  cursor: pointer;

  &:before {
    content: '';
    display: block;
    height: calc(100vh - 102px);
    @include breakpoint('mobile') {
      height: calc(75vh - 88px);
    }
  }

  // Slight gradient to increase contrast for text over image
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.15) 100%
    );
  }

  img,
  video {
    @include AbsoluteImage;
  }
}

.splitWrapper {
  a {
    width: 100%;
    position: relative;
    display: flex;
  }
}
.content {
  color: $white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  @media (max-width: 1278px) {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .title {
    @include SerifHero;
    cursor: pointer;

    @include breakpoint('mobile') {
      font-size: 48px;
      word-break: break-all;
    }
    @include breakpoint('xs') {
      font-size: 30px;
    }
  }

  .textItem {
    overflow-y: hidden;
    overflow-x: clip;
    padding-top: 1px; // so we dont cut off parts of the top of letters on iOS
    cursor: pointer;

    &.increaseLineHeight {
      padding-top: 20px;

      @include breakpoint('mobile') {
        padding-top: 12px;
      }
    }

    span {
      cursor: pointer;
      will-change: transform;
      display: block;
      transform: translateY(100%);
      animation-name: reveal;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      animation-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  .cta {
    @include CtaWithBorder;
  }
}

.midWrapper {
  position: relative;

  img {
    @include AbsoluteImage;
  }
}

body {
  &:global(.no-uspbar) {
    .wrapper,
    .midWrapper {
      &:before {
        height: calc(100vh - 66px);
        @include breakpoint('mobile') {
          height: calc(75vh - 88px);
        }
      }
    }
  }
}

.dark {
  .content {
    color: $black;
  }
  .cta {
    &:after,
    &:before {
      background: $black;
    }
  }
}
