@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

@keyframes reveal {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.wrapper {
  position: relative;

  .title {
    @include SansSerifText;
    @include breakpoint('mobile') {
      font-size: 16px;
    }
  }

  a {
    @include SansSerifText;
    font-size: 56px;
  }

  .text {
    p {
      @include SerifH1;
      @include breakpoint('mobile') {
        font-size: 24px;
        line-height: 1.3em;
      }
      line-height: 1.25em;
      letter-spacing: -0.03em;
      a {
        @include CtaWithBorder;
        display: block;
        width: fit-content;
        font-size: 18px;
        line-height: 25px;
        margin-top: 32px;

        &:before,
        &:after {
          background: $black;
        }

        @include breakpoint('mobile') {
          font-size: 15px;
        }
      }

      span {
        display: inline-block;
        overflow: hidden;
        vertical-align: sub;
        font-style: italic;
        padding: 0 4px;
        position: relative;
        top: 4px;
        @include breakpoint('mobile') {
          font-size: 24px;
          top: 1px;
        }

        div {
          will-change: transform;
          display: inline-block;
          transform: translateY(100%);
          transition: transform 2s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
    }

    &.active {
      p {
        span {
          div {
            transform: translateY(0);
          }
        }
      }
    }
  }
}
