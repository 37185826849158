@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';

.wrapper {
  &.green {
    background: $lightGreen;
  }

  a {
    text-decoration: none;
  }

  ::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }

  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

.title {
  @include SerifH2;
  font-size: 36px;
  line-height: 1em;
  @include breakpoint('mobile') {
    font-size: 24px;
  }
}

.link {
  @include SansSerifCTA;
  @include CtaWithBorder;
  padding: 0;
  text-decoration: none;
  &:before,
  &:after {
    background-color: $black;
  }
}

.leftSection {
  @include breakpoint('desktop') {
    position: sticky;
    top: 140px;
  }
}

.rightSection {
  @include breakpoint('mobile') {
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    > * {
      scroll-snap-align: center;

      &:last-child {
        // fix for safari to add some space to the right of the last item when scrolling to the end...
        position: relative;
        &::after {
          position: absolute;
          top: 0;
          content: '';
          right: -10px;
          width: 10px;
          height: 100%;
        }
      }
    }
  }
}
