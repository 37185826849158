@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.imageWrapper {
  position: relative;
  border-radius: 20px;

  @include breakpoint('mobile') {
    border-radius: 12px;
  }

  img {
    @include AbsoluteImage;
    border-radius: 20px;
    @include breakpoint('mobile') {
      border-radius: 12px;
    }
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding-bottom: 1px; // to avoid clipping bottom of the category labels on iOS

  .date {
    @include SansSerifTextSmall;
    font-size: 16px;
    @include breakpoint('mobile') {
      font-size: 15px;
    }
  }
  .title {
    @include SerifH4;
    cursor: pointer;
    @include breakpoint('mobile') {
      font-size: 20px;
      line-height: 1.4em;
    }
  }

  .label {
    @include SansSerifTextSmall;
    text-decoration: none;
    line-height: 1;
    font-size: 14px;
  }

  // &.full {
  //   position: relative;
  //   @include breakpoint('mobile') {
  //     .imageWrapper,
  //     img {
  //       border-radius: 0 !important;
  //     }
  //     .content {
  //       position: absolute;
  //       bottom: 0;
  //     }
  //   }
  // }
}

.small {
  .title {
    @include SerifH4;
    @include breakpoint('mobile') {
      font-size: 20px;
      line-height: 1.4em;
    }
  }
}
