@import 'src/styles/constants.scss';
@import 'src/styles/mixins.scss';
@import 'src/styles/transitions.scss';

.marquee {
  border-top: 1px solid $borderGray;
  border-bottom: 1px solid $borderGray;
}

.marquee img {
  font-size: 10vw;
  padding: 0 80px;
  max-height: 50px;
}
